import React, { useState, useEffect, useMemo, useCallback } from "react";

export default function Gallery() {
  const [current, setCurrent] = useState(0);
  const [isLoading, setIsLoading] = useState(false);
  const isMobile = window.innerWidth <= 768;
  
  const media = useMemo(() => [
    { src: "mower_1.webp", mobileSrc: "mower_1_mobile.webp", isVideo: false },
    { src: "mower_1.mp4", isVideo: true },
    { src: "mower_2.webp", mobileSrc: "mower_2_mobile.webp", isVideo: false },
    { src: "mower_2.mp4", isVideo: true },
    { src: "new/before1.jpeg", isVideo: false },
    { src: "new/before2.mp4", isVideo: true },
    { src: "new/after1.jpeg", isVideo: false },
    { src: "new/after2.mp4", isVideo: true },
    { src: "new/mower1.jpeg", isVideo: false },
    { src: "new/mower2.jpeg", isVideo: false },
    { src: "new/mowing-with-view1.jpeg", isVideo: false },
    { src: "new/mowing-with-view2.jpeg", isVideo: false },
    { src: "new/mowing-with-view3.jpeg", isVideo: false },
    { src: "new/mowing1.mp4", isVideo: true },
    { src: "new/mowing2.jpeg", isVideo: false },
    { src: "new/mowing3.jpeg", isVideo: false }
  ], []);

  const preloadMedia = useCallback((url, isVideo) => {
    return new Promise((resolve, reject) => {
      const element = isVideo ? document.createElement("video") : new Image();
      element.onload = () => resolve();
      element.onerror = reject;
      element.src = url;
    });
  }, []);

  useEffect(() => {
    const preloadNext = async () => {
      setIsLoading(true);
      const nextIndex = (current + 1) % media.length;
      const nextMedia = isMobile ? media[nextIndex].mobileSrc : media[nextIndex].src;

      try {
        await preloadMedia(nextMedia, media[nextIndex].isVideo);
        setIsLoading(false);
      } catch (error) {
        console.error("Preload failed:", error);
        setIsLoading(false);
      }
    };

    preloadNext();
  }, [current, isMobile, media, preloadMedia]);

  const handleNext = useCallback(() => {
    setCurrent((prevCurrent) => (prevCurrent === media.length - 1 ? 0 : prevCurrent + 1));
  }, [media.length]);

  const handlePrev = useCallback(() => {
    setCurrent((prevCurrent) => (prevCurrent === 0 ? media.length - 1 : prevCurrent - 1));
  }, [media.length]);

  return (
    <section className="gallery" id="gallery">
      <h2 className="section-title">Gallery</h2>
      <div id="gallery-wrapper">
        <button onClick={handleNext} className="nav-button next-button">
          {">"}
        </button>
        <div className="image-wrapper">
          {isLoading && <div className="loading-text">Loading...</div>}
          {media[current].isVideo ? (
            <video
              key={media[current].src}
              src={media[current].src}
              alt="slope mowing, mowing video"
              muted
              autoPlay
              loop
              playsInline
              className="gallery-image"
            />
          ) : (
            <img
              key={media[current].src}
              src={isMobile ? media[current].mobileSrc : media[current].src}
              alt="slope mowing, mower image"
              loading="lazy"
              className="gallery-image"
            />
          )}
        </div>
        <button onClick={handlePrev} className="nav-button prev-button">
          {"<"}
        </button>
      </div>
    </section>
  );
}
