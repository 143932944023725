import React, { useState, useCallback, useMemo } from 'react';
import { useForm, ValidationError } from "@formspree/react";

// Geolocation hook with fallback and minimal external dependencies
const useGeolocation = () => {
  const [isInUS, setIsInUS] = useState(true);
  const [isLoading, setIsLoading] = useState(false);

  const checkGeolocation = useCallback(async () => {
    setIsLoading(true);
    try {
      // Use browser's Geolocation API as a primary method
      if ("geolocation" in navigator) {
        return new Promise((resolve, reject) => {
          navigator.geolocation.getCurrentPosition(
            (position) => {
              // Here you could add more sophisticated country detection
              // For now, we'll keep it simple and allow all locations
              setIsInUS(true);
              resolve(true);
            },
            (error) => {
              console.warn('Geolocation error:', error);
              setIsInUS(true);
              resolve(true);
            },
            {
              enableHighAccuracy: false,
              timeout: 5000,
              maximumAge: 0
            }
          );
        });
      }
      
      // Fallback to IP-based geolocation using a CORS-friendly approach
      const response = await fetch('https://api.country.is/');
      
      if (!response.ok) {
        throw new Error('Geolocation service failed');
      }
      
      const data = await response.json();
      setIsInUS(data.country === 'US');
      
      return data.country === 'US';
    } catch (error) {
      console.warn('Geolocation check failed:', error);
      // Default to allowing form submission
      setIsInUS(true);
      return true;
    } finally {
      setIsLoading(false);
    }
  }, []);

  return { 
    isInUS, 
    isLoading, 
    checkGeolocation 
  };
};

export default function ContactForm() {
  const [state, handleSubmit] = useForm("mnqepqya");
  const { isInUS, isLoading } = useGeolocation();

  const handleFormSubmit = useCallback((e) => {
    // Simplified form submission logic
    if (!isInUS && !isLoading) {
      e.preventDefault();
      alert('Sorry, we are only accepting messages from the United States.');
      return;
    }
    handleSubmit(e);
  }, [isInUS, isLoading, handleSubmit]);

  // Memoize rendering to prevent unnecessary re-renders
  const formContent = useMemo(() => {
    if (state.succeeded) {
      return <p id="submit_message">Thanks for contacting us!</p>;
    }

    return (
      <section className="contact" id="contact">
        <h2 className="section-title">Contact Us</h2>
        <form onSubmit={handleFormSubmit} className="contact-form">
          <input
            id="name"
            type="text"
            name="name"
            placeholder="Full Name"
            required
            className="input-field"
          />
          <ValidationError prefix="Name" field="name" errors={state.errors} />
          
          <input
            id="email"
            type="email"
            name="_replyto"
            placeholder="Email"
            required
            className="input-field"
          />
          <ValidationError
            prefix="Email"
            field="_replyto"
            errors={state.errors}
          />
          
          <textarea
            id="message"
            name="message"
            placeholder="Your Message"
            required
            className="input-field"
          ></textarea>
          <ValidationError
            prefix="Message"
            field="message"
            errors={state.errors}
          />
          
          <button 
            type="submit" 
            className="submit-btn cta-button" 
            disabled={state.submitting}
          >
            Send Message
          </button>
        </form>
      </section>
    );
  }, [state, handleFormSubmit]);

  return <div>{formContent}</div>;
}
